import "../../index55.css";
import { jsxs as r, jsx as e } from "react/jsx-runtime";
import { _ as c } from "../../cs-piwRuCFZ.js";
import "../../type-BFTbooL7.js";
const o = "_spinner_50bgg_1", p = "_spinner-bounce_50bgg_9", a = "_spinner-bounce-delay_50bgg_1", n = {
  spinner: o,
  spinnerBounce: p,
  spinnerBounceDelay: a
};
function _(s) {
  const { className: i } = s;
  return /* @__PURE__ */ r("div", { className: c(n.spinner, i), children: [
    /* @__PURE__ */ e("div", { className: n.spinnerBounce }),
    /* @__PURE__ */ e("div", { className: n.spinnerBounce }),
    /* @__PURE__ */ e("div", { className: n.spinnerBounce })
  ] });
}
export {
  _ as default
};
