import "../../index41.css";
import { jsx as n } from "react/jsx-runtime";
import { _ as t } from "../../cs-piwRuCFZ.js";
import "../../type-BFTbooL7.js";
const l = "_page-container_1cfro_1", p = "_content_1cfro_2", e = {
  pageContainer: l,
  content: p
};
function N(o) {
  const {
    className: a,
    contentClassName: c,
    children: s,
    contentAs: r = "div",
    containerAs: i = "div"
  } = o, m = r;
  return /* @__PURE__ */ n(i, { className: t(e.pageContainer, a), children: /* @__PURE__ */ n(m, { className: t(e.content, c), children: s }) });
}
export {
  N as default
};
