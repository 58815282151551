import "../../../index68.css";
import { jsx as k } from "react/jsx-runtime";
import { useRef as C, useEffect as R, useCallback as _ } from "react";
import { _ as g } from "../../../cs-piwRuCFZ.js";
import "../../../type-BFTbooL7.js";
import h from "../../RawButton/index.js";
const O = "_generic-option_zv1n8_1", b = {
  genericOption: O
};
function j(c) {
  const {
    optionContainerClassName: i,
    contentRenderer: s,
    contentRendererParam: a,
    option: o,
    onClick: r,
    optionKey: e,
    focusedKey: n
  } = c, l = a(e, o), {
    containerClassName: m,
    title: p,
    ...f
  } = l, u = (n == null ? void 0 : n.key) === e, t = C(null);
  R(
    () => {
      n && n.key === e && !n.mouse && t.current && t.current.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
    },
    [e, n]
  );
  const d = _(
    () => {
      r(e, o);
    },
    [e, o, r]
  );
  return /* @__PURE__ */ k(
    h,
    {
      elementRef: t,
      className: g(
        b.genericOption,
        i,
        m
      ),
      onClick: d,
      title: p,
      name: e,
      focused: u,
      children: s(f)
    }
  );
}
export {
  j as default
};
