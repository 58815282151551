import "../../index37.css";
import { jsxs as M, jsx as a } from "react/jsx-runtime";
import { i as j } from "../../type-BFTbooL7.js";
import { _ as w } from "../../cs-piwRuCFZ.js";
import N from "../DefaultMessage/index.js";
import x from "../RawList/index.js";
const v = "_list_1whuj_1", D = "_compact_1whuj_5", E = "_pending_1whuj_8", s = {
  list: v,
  compact: D,
  pending: E
};
function b(o) {
  const {
    className: i,
    data: e,
    keySelector: n,
    renderer: m,
    rendererParams: c,
    pending: t,
    errored: p,
    filtered: l,
    errorMessage: d,
    emptyMessage: g,
    pendingMessage: f,
    filteredEmptyMessage: _,
    compact: r,
    withoutMessage: u = !1,
    messageClassName: y
  } = o, h = j(e) || e.length === 0;
  return /* @__PURE__ */ M(
    "div",
    {
      className: w(
        s.list,
        r && s.compact,
        t && s.pending,
        i
      ),
      children: [
        /* @__PURE__ */ a(
          x,
          {
            data: e,
            keySelector: n,
            renderer: m,
            rendererParams: c
          }
        ),
        !u && /* @__PURE__ */ a(
          N,
          {
            className: y,
            pending: t,
            filtered: l,
            empty: h,
            errored: p,
            compact: r,
            emptyMessage: g,
            filteredEmptyMessage: _,
            pendingMessage: f,
            errorMessage: d,
            overlayPending: !0
          }
        )
      ]
    }
  );
}
export {
  b as default
};
