import "../../index42.css";
import { jsx as n, jsxs as d, Fragment as m } from "react/jsx-runtime";
import { _ as o } from "../../cs-piwRuCFZ.js";
import "../../type-BFTbooL7.js";
import g from "../Container/index.js";
import f from "../Heading/index.js";
import N from "../PageContainer/index.js";
const b = "_page-header_1r8r3_1", u = "_container_1r8r3_4", H = "_breadcrumbs-container_1r8r3_9", x = "_header_1r8r3_13", j = "_heading_1r8r3_19", k = "_description_1r8r3_23", e = {
  pageHeader: b,
  container: u,
  breadcrumbsContainer: H,
  header: x,
  heading: j,
  description: k
};
function q(l) {
  const {
    className: _,
    heading: a,
    description: r,
    descriptionContainerClassName: h,
    actions: i,
    breadCrumbs: s,
    info: t,
    infoContainerClassName: p,
    wikiLink: c,
    headerClassName: C
  } = l;
  return i || s || t || r || a ? /* @__PURE__ */ n(
    N,
    {
      containerAs: "header",
      className: o(
        e.pageHeader,
        _
      ),
      children: /* @__PURE__ */ n(
        g,
        {
          className: e.container,
          icons: s,
          iconsContainerClassName: e.breadcrumbsContainer,
          actions: (i || c) && /* @__PURE__ */ d(m, { children: [
            i,
            c
          ] }),
          footerContent: t,
          footerContentClassName: p,
          childrenContainerClassName: o(e.header, C),
          children: (a || r) && /* @__PURE__ */ d(m, { children: [
            /* @__PURE__ */ n(
              f,
              {
                level: 1,
                className: e.heading,
                children: a
              }
            ),
            r && /* @__PURE__ */ n("div", { className: o(e.description, h), children: r })
          ] })
        }
      )
    }
  ) : null;
}
export {
  q as default
};
