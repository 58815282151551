import "../../index38.css";
import { jsxs as l, jsx as s } from "react/jsx-runtime";
import { a as i } from "../../type-BFTbooL7.js";
import { _ as g } from "../../cs-piwRuCFZ.js";
import v from "../Spinner/index.js";
const u = "_message_176iw_1", x = "_icon_176iw_13", D = "_spinner_176iw_17", j = "_title_176iw_22", T = "_errored_176iw_27", y = "_compact_176iw_33", e = {
  message: u,
  icon: x,
  spinner: D,
  title: j,
  errored: T,
  compact: y
};
function z(d) {
  const {
    className: p,
    pending: r = !1,
    variant: _,
    icon: c,
    title: t,
    description: n,
    actions: a,
    compact: f = !1,
    errored: o,
    erroredTitle: m,
    erroredDescription: w
  } = d, h = o ? i(m) : i(t), N = o ? i(w) : i(n);
  return /* @__PURE__ */ l(
    "div",
    {
      className: g(
        e.message,
        _ === "error" && e.errored,
        f && e.compact,
        p
      ),
      children: [
        (c || r) && /* @__PURE__ */ l("div", { className: e.icon, children: [
          r && /* @__PURE__ */ s(v, { className: e.spinner }),
          !r && c
        ] }),
        h && /* @__PURE__ */ s("div", { className: e.title, children: o ? m : t }),
        N && /* @__PURE__ */ s("div", { className: e.description, children: n }),
        a && /* @__PURE__ */ s("div", { className: e.actions, children: a })
      ]
    }
  );
}
export {
  z as default
};
