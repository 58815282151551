import "../../index53.css";
import { jsx as eo } from "react/jsx-runtime";
import { useState as C, useMemo as k, useCallback as d } from "react";
import { a as q, i as T, b as no } from "../../type-BFTbooL7.js";
import { _ as to } from "../../cs-piwRuCFZ.js";
import { u as io } from "../../list-Bv4dmpyM.js";
import { l as E } from "../../transform-VOqkQszh.js";
import so from "../SelectInputContainer/index.js";
import { d as I } from "../../common-CLw-EvUg.js";
import ao from "./Option/index.js";
const ro = "_option-container_2k92q_1", co = "_option_2k92q_1", lo = "_active_2k92q_9", po = "_icon_2k92q_12", m = {
  optionContainer: ro,
  option: co,
  active: lo,
  icon: po
}, x = [];
function wo(h) {
  const {
    keySelector: t,
    labelSelector: i,
    descriptionSelector: S,
    name: u,
    onChange: F,
    onOptionsChange: O,
    options: L,
    optionsPending: M,
    optionsErrored: V,
    value: n,
    sortFunction: f,
    searchOptions: W,
    onSearchValueChange: a,
    onShowDropdownChange: l,
    hideOptionFilter: g,
    selectedOnTop: D,
    onEnterWithoutOption: v,
    ...R
  } = h, p = L ?? x, b = W ?? x, [s, _] = C(), [j, K] = C(!1), [A, B] = C(!1), [
    z,
    y
  ] = C(), [w, N] = C({}), G = k(
    () => E(p, t, i),
    [p, t, i]
  ), H = q(n) ? G[n] ?? "?" : "", P = k(
    () => {
      const o = p == null ? void 0 : p.find((e) => t(e) === n);
      return T(o) ? [] : [o];
    },
    [n, p, t]
  ), J = k(
    () => {
      const o = io(
        [...b, ...P],
        t
      );
      if (!D)
        return f ? f(o, s, i) : o;
      const e = o.filter((c) => w[t(c)]), r = o.filter((c) => !w[t(c)] && (T(g) || g(c)));
      return f ? [
        ...I(e, s, i),
        ...f(r, s, i)
      ] : [
        ...I(e, s, i),
        ...r
      ];
    },
    [
      D,
      t,
      i,
      s,
      b,
      w,
      P,
      f,
      g
    ]
  ), Q = d(
    (o) => {
      _(o), a && a(o);
    },
    [a]
  ), U = d(() => {
    K(!1), l && l(!1), _(void 0), a && a(void 0), v && v(s);
  }, [s, l, v, a]), X = d(
    (o) => {
      K(o), l && l(o), o ? (N(
        E(
          n ? [n] : [],
          (e) => e,
          () => !0
        )
      ), y(n ? { key: n } : void 0)) : (N({}), y(void 0), _(void 0), a && a(void 0));
    },
    [n, a, l]
  ), Y = d(
    (o, e) => {
      const r = o === n;
      return {
        label: i(e),
        description: S ? S(e) : void 0,
        containerClassName: to(m.optionContainer, r && m.active),
        title: i(e),
        className: m.option,
        iconClassName: m.icon
      };
    },
    [n, i, S]
  ), Z = d(
    (o, e) => {
      O && O((r) => {
        const c = r ?? [];
        return c.find((oo) => t(oo) === o) ? r : [...c, e];
      }), F(o, u, e);
    },
    [F, u, O, t]
  ), $ = d(
    () => {
      if (!h.nonClearable) {
        const o = h.onChange;
        o(void 0, u, void 0);
      }
    },
    // eslint-disable-next-line react/destructuring-assignment
    [u, h.onChange, h.nonClearable]
  );
  return /* @__PURE__ */ eo(
    so,
    {
      ...R,
      name: u,
      options: J,
      optionsPending: M,
      optionsErrored: V,
      optionsFiltered: no(s) && s.length > 0,
      optionKeySelector: t,
      optionRenderer: ao,
      optionRendererParams: Y,
      optionContainerClassName: m.optionContainer,
      onOptionClick: Z,
      valueDisplay: H,
      onClearButtonClick: $,
      searchText: s,
      onSearchTextChange: Q,
      dropdownShown: j,
      onDropdownShownChange: X,
      focused: A,
      onFocusedChange: B,
      focusedKey: z,
      onFocusedKeyChange: y,
      hasValue: q(n),
      persistentOptionPopup: !1,
      onEnterWithoutOption: U
    }
  );
}
export {
  wo as default
};
