import "../../../index67.css";
import { jsxs as e, jsx as i } from "react/jsx-runtime";
import { CheckLineIcon as p } from "@ifrc-go/icons";
import { _ as n } from "../../../cs-piwRuCFZ.js";
import "../../../type-BFTbooL7.js";
const _ = "_option_11p37_1", d = "_icon_11p37_6", m = "_label_11p37_14", f = "_overflow-container_11p37_20", v = "_description_11p37_25", o = {
  option: _,
  icon: d,
  label: m,
  overflowContainer: f,
  description: v
};
function u(s) {
  const {
    className: c,
    label: l,
    description: t,
    iconClassName: r,
    labelClassName: a
  } = s;
  return /* @__PURE__ */ e("div", { className: n(o.option, c), children: [
    /* @__PURE__ */ i("div", { className: n(o.icon, r), children: /* @__PURE__ */ i(p, {}) }),
    /* @__PURE__ */ e("div", { className: n(o.label, a), children: [
      /* @__PURE__ */ i("div", { className: o.overflowContainer, children: l }),
      /* @__PURE__ */ i("div", { className: n(o.overflowContainer, o.description), children: t })
    ] })
  ] });
}
export {
  u as default
};
