import "../../index22.css";
import { jsx as t, jsxs as F } from "react/jsx-runtime";
import { useMemo as ce } from "react";
import { i as de } from "../../type-BFTbooL7.js";
import { _ as i } from "../../cs-piwRuCFZ.js";
import me from "../DefaultMessage/index.js";
import he from "../Footer/index.js";
import fe from "../Header/index.js";
import a from "../../hooks/useSpacingTokens.js";
const pe = "_container_1s0hl_1", _e = "_header_1s0hl_8", Ce = "_centered-header-description-container_1s0hl_11", we = "_centered-description_1s0hl_16", ge = "_border_1s0hl_23", ue = "_filter_1s0hl_29", ve = "_with-grid-view-in-filter_1s0hl_30", Ne = "_content_1s0hl_36", De = "_pending-overlaid_1s0hl_39", Ve = "_message_1s0hl_44", Ie = "_footer_1s0hl_50", Ge = "_with-grid-view_1s0hl_30", He = "_two-columns_1s0hl_64", Fe = "_three-columns_1s0hl_79", Me = "_four-columns_1s0hl_93", Te = "_five-columns_1s0hl_97", Oe = "_with-vertical-view_1s0hl_102", ke = "_with-overflow-in-content_1s0hl_109", e = {
  container: pe,
  header: _e,
  centeredHeaderDescriptionContainer: Ce,
  centeredDescription: we,
  border: ge,
  filter: ue,
  withGridViewInFilter: ve,
  content: Ne,
  pendingOverlaid: De,
  message: Ve,
  footer: Ie,
  withGridView: Ge,
  twoColumns: He,
  threeColumns: Fe,
  fourColumns: Me,
  fiveColumns: Te,
  withVerticalView: Oe,
  withOverflowInContent: ke
}, ye = {
  2: e.twoColumns,
  3: e.threeColumns,
  4: e.fourColumns,
  5: e.fiveColumns
};
function Be(M) {
  const {
    actions: g,
    actionsContainerClassName: T,
    children: h,
    childrenContainerClassName: O,
    className: k,
    containerRef: y,
    contentViewType: l = "default",
    ellipsizeHeading: P,
    filters: f,
    filtersContainerClassName: b,
    footerActions: u,
    footerActionsContainerClassName: S,
    footerClassName: x,
    footerContent: v,
    footerContentClassName: R,
    footerIcons: N,
    headerClassName: W,
    headerDescription: o,
    withCenteredHeaderDescription: p,
    headerDescriptionContainerClassName: j,
    headerElementRef: z,
    heading: D,
    headingClassName: A,
    headingContainerClassName: B,
    headingDescription: V,
    headingDescriptionContainerClassName: E,
    headingLevel: L,
    headingSectionClassName: q,
    icons: I,
    iconsContainerClassName: J,
    numPreferredGridContentColumns: K = 2,
    spacing: n = "default",
    withGridViewInFilter: G = !1,
    withHeaderBorder: Q = !1,
    withFooterBorder: U = !1,
    withOverflowInContent: X = !1,
    withInternalPadding: r = !1,
    withoutWrapInHeading: Y = !1,
    withoutWrapInFooter: Z = !1,
    pending: s = !1,
    overlayPending: _ = !1,
    empty: c = !1,
    errored: d = !1,
    filtered: C = !1,
    compactMessage: $ = !1,
    errorMessage: ee,
    emptyMessage: ne,
    pendingMessage: te,
    filteredEmptyMessage: ie
  } = M, w = N || v || u, H = D || g || I || o || V, oe = a({ spacing: n }), m = a({
    spacing: n,
    mode: "padding-h"
  }), re = a({
    spacing: n,
    mode: "padding-v"
  }), se = a({
    spacing: n,
    mode: "gap",
    inner: !0
  }), ae = a({
    spacing: n,
    mode: "grid-gap"
  }), le = ce(
    () => de(o) ? null : p ? /* @__PURE__ */ t("div", { className: e.centeredDescription, children: o }) : o,
    [o, p]
  );
  return !H && !f && !h && !w && !c && !s && !d && !C ? null : /* @__PURE__ */ F(
    "div",
    {
      ref: y,
      className: i(
        e.container,
        oe,
        r && re,
        X && e.withOverflowInContent,
        l === "grid" && e.withGridView,
        l === "grid" && ye[K],
        l === "vertical" && e.withVerticalView,
        k
      ),
      children: [
        H && /* @__PURE__ */ t(
          fe,
          {
            actions: g,
            className: i(
              e.header,
              r && m,
              W
            ),
            elementRef: z,
            actionsContainerClassName: T,
            ellipsizeHeading: P,
            heading: D,
            headingLevel: L,
            icons: I,
            iconsContainerClassName: J,
            childrenContainerClassName: i(
              p && e.centeredHeaderDescriptionContainer,
              j
            ),
            headingSectionClassName: q,
            headingClassName: A,
            headingContainerClassName: B,
            wrapHeadingContent: !Y,
            headingDescription: V,
            headingDescriptionContainerClassName: E,
            spacing: n,
            children: le
          }
        ),
        Q && /* @__PURE__ */ t("div", { className: e.border }),
        f && /* @__PURE__ */ t(
          "div",
          {
            className: i(
              e.filter,
              G && e.withGridViewInFilter,
              G && ae,
              b,
              r && m
            ),
            children: f
          }
        ),
        (h || c || s || d || C) && /* @__PURE__ */ F(
          "div",
          {
            className: i(
              e.content,
              l !== "default" && se,
              r && m,
              _ && s && e.pendingOverlaid,
              O
            ),
            children: [
              /* @__PURE__ */ t(
                me,
                {
                  className: e.message,
                  pending: s,
                  filtered: C,
                  errored: d,
                  empty: c,
                  compact: $,
                  overlayPending: _,
                  emptyMessage: ne,
                  filteredEmptyMessage: ie,
                  pendingMessage: te,
                  errorMessage: ee
                }
              ),
              !c && !d && (!s || _) && h
            ]
          }
        ),
        w && U && /* @__PURE__ */ t("div", { className: e.border }),
        w && /* @__PURE__ */ t(
          he,
          {
            actions: u,
            icons: N,
            childrenContainerClassName: R,
            className: i(
              e.footer,
              r && m,
              x
            ),
            actionsContainerClassName: S,
            spacing: n,
            withoutWrap: Z,
            children: v
          }
        )
      ]
    }
  );
}
export {
  Be as default
};
