import "../../index50.css";
import { jsx as p } from "react/jsx-runtime";
import c from "react";
import { a as l } from "../../type-BFTbooL7.js";
import { _ as f } from "../../cs-piwRuCFZ.js";
const _ = "_raw-input_8rtzt_1", d = {
  rawInput: _
};
function I(r) {
  const {
    className: o,
    onChange: a,
    elementRef: s,
    value: u,
    name: t,
    ...i
  } = r, m = c.useCallback((e) => {
    const n = e.currentTarget.value;
    a && a(
      n === "" ? void 0 : n,
      t,
      e
    );
  }, [t, a]);
  return /* @__PURE__ */ p(
    "input",
    {
      ...i,
      ref: s,
      className: f(
        d.rawInput,
        o
      ),
      name: l(t) ? String(t) : void 0,
      onChange: m,
      value: u ?? ""
    }
  );
}
export {
  I as default
};
