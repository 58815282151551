import "../../index84.css";
import { jsx as m } from "react/jsx-runtime";
import { useMemo as T } from "react";
import { AnalysisIcon as v } from "@ifrc-go/icons";
import { _ as y } from "../../cs-piwRuCFZ.js";
import "../../type-BFTbooL7.js";
import F from "../Message/index.js";
import M from "../../hooks/useTranslation.js";
const D = "common", _ = {
  messageTitleFetching: "Fetching data...",
  messageTitleFilterdDataNotAvailable: "Data is not available for selected filter!",
  messageTitleDataNotAvailable: "Data is not available!",
  messageTitleDataFailedToFetch: "Failed to fetch data!"
}, b = {
  namespace: D,
  strings: _
}, h = "_default-message_wu986_1", N = "_overlay_wu986_2", g = {
  defaultMessage: h,
  overlay: N
};
function q(c) {
  const {
    className: f,
    compact: u,
    pending: e,
    overlayPending: d,
    filtered: i,
    empty: t,
    errored: s,
    emptyMessage: l,
    filteredEmptyMessage: r,
    pendingMessage: o,
    errorMessage: n
  } = c, a = M(b), p = T(
    () => e ? o ?? a.messageTitleFetching : s ? n ?? a.messageTitleDataFailedToFetch : i ? r ?? a.messageTitleFilterdDataNotAvailable : t ? l ?? a.messageTitleDataNotAvailable : null,
    [
      t,
      e,
      i,
      s,
      l,
      r,
      o,
      n,
      a
    ]
  );
  return !t && !e && !s ? null : /* @__PURE__ */ m(
    F,
    {
      className: y(
        g.defaultMessage,
        e && d && g.overlay,
        f
      ),
      icon: /* @__PURE__ */ m(v, {}),
      compact: u,
      title: p,
      pending: e
    }
  );
}
export {
  q as default
};
