import "../../index44.css";
import { jsxs as o, Fragment as d, jsx as i } from "react/jsx-runtime";
import { useState as _, useRef as a, useEffect as x } from "react";
import { AlertLineIcon as E } from "@ifrc-go/icons";
import { i as c } from "../../type-BFTbooL7.js";
import { _ as N } from "../../cs-piwRuCFZ.js";
import R from "../Popup/index.js";
const h = "_input-error_1sxiz_1", z = "_slide-down_1sxiz_1", D = "_pointer_1sxiz_14", I = "_fade-in_1sxiz_1", r = {
  inputError: h,
  slideDown: z,
  pointer: D,
  fadeIn: I
};
function A(m) {
  const {
    children: t,
    className: f,
    disabled: p
  } = m, [u, l] = _(!1), n = a(), e = a(null);
  return x(
    () => {
      if (c(e.current))
        return;
      const {
        current: {
          parentElement: s
        }
      } = e;
      c(s) || (n.current = s, l(!0));
    },
    []
  ), /* @__PURE__ */ o(d, { children: [
    !u && /* @__PURE__ */ i(
      "div",
      {
        className: r.tooltipDummy,
        ref: e
      }
    ),
    t && !p && /* @__PURE__ */ o(
      R,
      {
        className: N(r.inputError, f),
        pointerClassName: r.pointer,
        parentRef: n,
        children: [
          /* @__PURE__ */ i(E, { className: r.icon }),
          t
        ]
      }
    )
  ] });
}
export {
  A as default
};
